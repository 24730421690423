export default class ShozokuExp {
  incode: string;
  permitFixTime: string;
  basicUnitPrice: string;
  mealUnitPrice: string;
  parkingUnitPrice: string;
  baseDaytimeActiveHour: string;
  constructor(
    incode = "",
    permitFixTime = "",
    basicUnitPrice = "",
    mealUnitPrice = "",
    parkingUnitPrice = "",
    baseDaytimeActiveHour = ""
  ) {
    this.incode = incode;
    this.permitFixTime = permitFixTime;
    this.basicUnitPrice = basicUnitPrice;
    this.mealUnitPrice = mealUnitPrice;
    this.parkingUnitPrice = parkingUnitPrice;
    this.baseDaytimeActiveHour = baseDaytimeActiveHour;
  }
}
